/**
 * @file
 * SVG fancy line drawing library.
 */

import Easing from './Easing';
import { clampNumber, interpolateProperty } from './Utilities';
import { radians } from './Math';
import { parseDataAttribute } from './DataAttributes';

const pathSampleCache = {};

const propCache = {};

export default class CurveRenderer {
  constructor(elem, samplerProps) {
    this.elem = elem;
    this.samplerProps = samplerProps || { precision: 20 };
  }

  prepare() {
    propCache[this.elem.id] = {
      width: parseDataAttribute(
        this.elem.getAttribute("data-stroke-widths"),
        "width"
      ),
      fill: parseDataAttribute(
        this.elem.getAttribute("data-stroke-color"),
        "fill"
      ),
      opacity: parseDataAttribute(
        this.elem.getAttribute("data-stroke-opacity"),
        "opacity"
      )
    };

    this.elem.setAttribute(
      "data-computed-sample-length",
      this.samplePath(this.elem, this.samplerProps, propCache[this.elem.id])
        .length
    );
  }

  render(distance, next) {
    if (!this.elem.getAttribute("data-computed-sample-length")) {
      this.prepare();
    }
    const samples = this.samplePath(
      this.elem,
      Object.assign({ distance }, this.samplerProps),
      propCache[this.elem.id]
    );
    const sampleQuads = this.quadStrip(samples);
    const polys = this.renderStrip(this.elem, sampleQuads);
    if (next && typeof next === "function") {
      next(polys);
    }
  }

  samplePath(path, samplePrefs, variableLengthData) {
    const distance =
      typeof samplePrefs.distance === "undefined" ||
      samplePrefs.distance === null ||
      isNaN(samplePrefs.distance)
        ? 1
        : clampNumber(samplePrefs.distance, 0, 1);
    if (distance === 0) {
      return [];
    }
    const samples = this.sampleEntirePath(
      path,
      samplePrefs,
      variableLengthData
    );
    return samples.filter(sample => sample.t <= distance);
  }

  sampleEntirePath(path, samplePrefs, variableLengthData) {
    if (pathSampleCache[path.id]) {
      return pathSampleCache[path.id];
    }

    const totalLength = path.getTotalLength();
    let desiredLength = totalLength;

    let segments;
    let precision;
    if (samplePrefs.segments) {
      segments = samplePrefs.segments;
      precision = desiredLength / segments;
    } else if (samplePrefs.precision) {
      precision = samplePrefs.precision;
      segments = desiredLength / precision;
    }

    let i = 0;
    let lineSegments = [0];
    while ((i += precision) < desiredLength) {
      lineSegments.push(i);
    }
    lineSegments.push(desiredLength);

    // This is the most expensive part of this script.
    // Good thing we only run it once, eh?
    const pointsAtLength = lineSegments.map(length => [
      length,
      path.getPointAtLength(length)
    ]);

    pathSampleCache[path.id] = pointsAtLength.map((item, index) => {
      const [length, p] = item;
      const pPre =
        index === 0 ? pointsAtLength[0][1] : pointsAtLength[index - 1][1];
      const pPost =
        index === pointsAtLength.length - 1
          ? pointsAtLength[pointsAtLength.length - 1][1]
          : pointsAtLength[index + 1][1];

      const deg =
        Math.atan2(pPre.y - pPost.y, pPre.x - pPost.x) * (180 / Math.PI);
      const lengthPercent = length / totalLength;
      const span = {
        x: p.x,
        y: p.y,
        alpha: deg,
        t: lengthPercent
      };
      Object.keys(variableLengthData).forEach(prop => {
        span[prop] = interpolateProperty(
          prop,
          variableLengthData[prop],
          lengthPercent
        );
      });
      return span;
    });
    return pathSampleCache[path.id];
  }

  quadStrip(samples) {
    return samples.reduce((agg, toSample, index, sampleArray) => {
      if (index === 0) {
        return agg;
      }

      const fromSample = sampleArray[index - 1];
      const points = [];
      points.push(
        this.translatePoint(fromSample, fromSample.alpha, fromSample.width)
      );
      points.push(
        this.translatePoint(toSample, toSample.alpha, toSample.width)
      );
      points.push(
        this.translatePoint(toSample, toSample.alpha, -1 * toSample.width)
      );
      points.push(
        this.translatePoint(fromSample, fromSample.alpha, -1 * fromSample.width)
      );

      const reducedPoints = points.reduce((agg, item, index) => {
        if (index > 0 && this.pointsSame(item, agg[index - 1])) {
          return agg;
        }
        agg.push(item);
        return agg;
      }, []);

      if (
        this.pointsSame(
          reducedPoints[reducedPoints.length - 1],
          reducedPoints[0]
        )
      ) {
        reducedPoints.pop();
      }

      agg.push({
        index: index - 1,
        t: fromSample.t + ((toSample.t - fromSample.t) / 2),
        points: reducedPoints,
        fromSample,
        toSample
      });
      return agg;
    }, []);
  }

  pointsSame(p1, p2) {
    if (typeof p1 === "undefined" || typeof p2 === "undefined") {
      return true;
    }
    return p1[0] === p2[0] && p1[1] === p2[1];
  }

  translatePoint(point, angle, length) {
    let x = point.x;
    let y = point.y;
    const rad = radians(angle % 360);

    x -= length * Math.sin(rad);
    y += length * Math.cos(rad);

    return [x, y];
  }

  pointsToSvgPath(points) {
    return (
      points.reduce((line, point, index) => {
        return (
          line +
          " " +
          (index === 0 ? "M " : "L ") +
          point[0].toFixed(6) +
          " " +
          point[1].toFixed(6)
        );
      }, "") + " Z"
    );
  }

  renderGroup(sourceElem) {
    const existingGroup = sourceElem.parentNode.querySelector(`[data-source-id-group="${sourceElem.id}"]`);
    if (
      typeof existingGroup !== 'undefined' &&
      existingGroup !== null
    ) {
      return existingGroup;
    }
    const group = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );
    group.setAttribute("data-source-id-group", sourceElem.id);
    sourceElem.parentNode.appendChild(group);
    return group;
  }

  renderStrip(sourceElem, quads) {
    const group = this.renderGroup(sourceElem);
    const { cull, render } = this.getRenderCullList(sourceElem, quads);
    if (cull.length) {
      cull.forEach(el => el.parentNode.removeChild(el));
    }
    if (render.length) {
      render.forEach(quad => {
        const path = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path.setAttribute("d", this.pointsToSvgPath(quad.points));
        if (quad.fromSample.fill !== null) {
          path.setAttribute("fill", quad.fromSample.fill);
        }
        if (quad.fromSample.opacity !== null) {
          path.setAttribute("opacity", quad.fromSample.opacity);
        }
        path.setAttribute("data-source-id", sourceElem.id);
        path.setAttribute("data-source-t", quad.t);
        path.setAttribute("data-source-poly-index", quad.index);
        group.appendChild(path);
      });
    }

    return sourceElem.parentNode.querySelectorAll(
      '[data-source-id="' + sourceElem.id + '"]'
    );
  }

  getRenderCullList(sourceElem, quads) {
    const existingPolys = Array.prototype.slice.call(
      sourceElem.parentNode.querySelectorAll(
        '[data-source-id="' + sourceElem.id + '"]'
      )
    );
    if (existingPolys.length === 0) {
      return { render: quads, cull: [] };
    }
    if (quads.length === 0) {
      return { render: [], cull: existingPolys };
    }
    if (existingPolys.length === quads.length) {
      return { render: [], cull: [] };
    }

    if (existingPolys.length > quads.length) {
      return {
        render: [],
        cull: existingPolys.filter(el => {
          const elIndex = parseInt(
            el.getAttribute("data-source-poly-index"),
            10
          );
          return quads.length < elIndex;
        })
      };
    }

    const existingIndexList = existingPolys.map(el =>
      el.getAttribute("data-source-poly-index")
    );
    const render = quads.filter(
      quad =>
        !existingIndexList.includes(quad.index.toString()) &&
        quad.points.length >= 3
    );
    return {
      cull: [],
      render
    };
  }
}
