/**
 * @file
 * Common utility functions.
 */

import Easing from './Easing';
import { parseColor, rgbToHsl } from './Colour';

const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const clampNumber = (number, min, max) => Math.min(Math.max(number, min), max);

const clampAndExpand = (number, min, max) => clampNumber((number - min) / (max - min), 0, 1);

/**
 * Ease a value based on a timing function.
 *
 * @param number fromValue
 *   The value at time 0.
 * @param number toValue
 *   The value at time 1.
 * @param number t
 *   The current time.
 * @param number string|null
 *   The name of the function to provide easing.
 *   NULL or undefined uses linear easing.
 *
 * @return number
 *   The value having been eased.
 */
const easeVariable = (fromValue, toValue, t, easingFunction) => {
  if (t === 0) {
    return fromValue;
  }
  if (t === 1) {
    return toValue;
  }
  return fromValue + (toValue - fromValue) * (
    easingFunction && typeof Easing[easingFunction] === 'function' ?
    Easing[easingFunction](t) :
    Easing.linear(t)
  );
};

/**
 * Interpolate a property to a value determined by an array of variations.
 *
 * @param string prop
 *   The property to interpolate.
 * @param array propVariation
 *   An array of all property variation objects.
 *   Each object contains two keys:
 *   - t: a number between 0 and 1 determining timing of that value,
 *   - value: the value, which corresponds to prop.
 * @param number t
 *   The timing number, between 0 and 1.
 *
 * @return mixed
 *   The property interpolated to that timing, depending on the curve
 *   specified by propVariation.
 */
const interpolateProperty = (prop, propVariation, t) => {
  if (!propVariation.length) {
    return null;
  }
  const fromCandidates = propVariation.filter(val => val.t <= t);
  const from = fromCandidates.length
    ? fromCandidates.pop()
    : propVariation[propVariation.length - 1];
  const fromIndex = propVariation.indexOf(from);
  const to =
    fromIndex < propVariation.length - 1
      ? propVariation[fromIndex + 1]
      : from;
  const segmentT = from.t === to.t ? 0 : (t - from.t) / (to.t - from.t);

  // Easing for fill colour.
  if (prop === "fill") {
    if (segmentT === 0 || segmentT < 0) {
      return from.value;
    }
    if (segmentT === 1 || segmentT > 1) {
      return to.value;
    }

    // @TODO: Back to RGB?
    const fromHsl = rgbToHsl.apply(null, parseColor(from.value));
    const toHsl = rgbToHsl.apply(null, parseColor(to.value));
    const h = easeVariable(fromHsl[0], toHsl[0], segmentT);
    const s = easeVariable(fromHsl[1], toHsl[1], segmentT);
    const l = easeVariable(fromHsl[2], toHsl[2], segmentT);
    return (
      "hsl(" + [h * 360.0, s * 100 + "%", l * 100 + "%"].join(", ") + ")"
    );
  }

  // Easing for z-index.
  if (prop === "z-index") {
    return Math.round(easeVariable(from.value, to.value, segmentT));
  }

  // Default easing, handles width, opacity and timing.
  return easeVariable(from.value, to.value, segmentT);
};

export {
  clampNumber,
  clampAndExpand,
  debounce,
  easeVariable,
  interpolateProperty,
}
