// @file
// Waranara homepage.

import CurveRenderer from './lib/CurveRenderer';
import { clampAndExpand, debounce, interpolateProperty } from './lib/Utilities';
import { parseDataAttribute } from './lib/DataAttributes';

window.WaranaraHomepageAnimation = new (function () {
  /**
   * Bunch of rubbish to figure out how tall the window is.
   * Thanks, iOS!
   */
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const innerHeight = () => iOS ? (screen.height - 40) : window.innerHeight;

  /*
   * Variables to control behaviour of page.
   *
   * scrollPanes is the number of panes that are displayed in the
   * horizontal scrolling region on this page.
   *
   * paneDuration determines the scroll distance available for each
   * page-content item. It changes whenever the page is resized.
   *
   * controller contains the ScrollMagic container, which is only
   * initialised when the scene is.
   *
   * scenes contains each ScrollMagic scene, which has their own unique
   * timelines and tweens. Each scene is linked to start when a given
   * scroll-item element scrolls into view, and has a duration of
   * paneDuration.
   *
   * debugAnimation switches on trigger displays for ScrollMagic.
   */
  var scrollPanes = $('.scroll-item').length;
  var paneDuration = innerHeight();
  var controller;
  var scenes = {};
  const debugAnimation = false;
  const disableBackScroll = true;

  /**
   * Variable to control whether the line should be drawn *yet*.
   */
  let renderLineEnabled = false;

  const svgContainer = document.querySelector('.page__svg-container');
  const pathContainer = document.querySelector('#page__svg-line');
  const curveRenderers = {};
  const curveTiming = {};
  const buildRenderer = (i) => {
    const el = document.querySelector(`#page__LongLine${i}`);
    curveTiming[i - 1] = parseDataAttribute(el.getAttribute('data-scroll-draw'), 'timing');
    curveRenderers[i - 1] = new CurveRenderer(el, { precision: 5 });
    curveRenderers[i - 1].prepare();  
  };
  let i;
  for (i = 1; i <= 5; i++) {
    buildRenderer(i);
  }
  pathContainer.removeAttribute('hidden');

  const afterRenderStrip = (polys, index) => {
    if (!polys.length) {
      return;
    }
    polys[0].parentNode.setAttribute("filter", "url(#page__svg-container__line-glow)");
    polys.forEach(el => {
      el.setAttribute("stroke", el.getAttribute("fill"));
    });
  };

  let lastScroll = 0;
  const renderCurves = (scrollPos) => {
    console.log(
      scrollPos
    );
    if (!renderLineEnabled) {
      return;
    }
    if (disableBackScroll && scrollPos <= lastScroll) {
      return;
    }
    lastScroll = scrollPos;

    curveRenderers[0].render(
      interpolateProperty('timing', curveTiming[0], scrollPos),
      afterRenderStrip
    );
    curveRenderers[1].render(
      interpolateProperty('timing', curveTiming[1], scrollPos),
      afterRenderStrip
    );
    curveRenderers[2].render(
      interpolateProperty('timing', curveTiming[2], scrollPos),
      afterRenderStrip
    );
    curveRenderers[3].render(
      interpolateProperty('timing', curveTiming[3], scrollPos),
      afterRenderStrip
    );
    curveRenderers[4].render(
      interpolateProperty('timing', curveTiming[4], scrollPos),
      afterRenderStrip
    );

    console.log(
      interpolateProperty('timing', curveTiming[0], scrollPos),
      interpolateProperty('timing', curveTiming[1], scrollPos),
      interpolateProperty('timing', curveTiming[2], scrollPos),
      interpolateProperty('timing', curveTiming[3], scrollPos),
      interpolateProperty('timing', curveTiming[4], scrollPos)
    );
  };

  const scrollHeight = () => document.body.clientHeight - innerHeight();
  const scrollWidth = () => document.querySelector('.page-wrap').clientWidth - window.innerWidth;
  const paneWidth = () => 177.78 * innerHeight() / 100;
  const paneGutter = () => (paneWidth() - window.innerWidth) / 2;

  const paneCentreGutter = (paneIndex) => {
    return (paneWidth() * paneIndex) + paneGutter();
  };
  let leftHandGutter = 0;
  let scrollEventActive = false;

  const pageWrap = document.querySelector(".page-wrap");
  const pageWrapScrollTiming = parseDataAttribute(pageWrap.getAttribute('data-scroll-drive'), 'timing');
  const svgContainerScrollZIndex = parseDataAttribute(svgContainer.getAttribute('data-scroll-z-index'), 'z-index');

  /**
   * Control horizontal scroll with vertical scroll.
   */
  const handleScrollEvent = function(e) {
    if (!scrollEventActive) {
      return;
    }
    const currentScrollPos = window.scrollY / scrollHeight();
    const easedLeftPos = interpolateProperty('timing', pageWrapScrollTiming, currentScrollPos);
    const leftPos = -1 * easedLeftPos * (scrollPanes - 1) * 177.78;
    const adjustmentPos = easedLeftPos * (window.innerWidth - paneWidth()) + (window.innerWidth >= 768 ? 17 : 0);
    const easedGutter = (easedLeftPos - 1) * leftHandGutter;

    pageWrap.style.transform =
      "translateX(" + leftPos + "vh) translateX(" + adjustmentPos + "px) translateX(" + easedGutter + "px)";
    svgContainer.style.zIndex = interpolateProperty(
      'z-index',
      svgContainerScrollZIndex,
      currentScrollPos
    );
    debounce(renderCurves, 50)(currentScrollPos);
  };

  /**
   * Initialise scene with all its animations.
   */
  const initScene = function() {
    document.querySelector('.page-shell').className += ' page-shell--active';

    // Initialise ScrollMagic controller.
    const controller = new ScrollMagic.Controller();

    // Country and culture animation.
    const cultureFirstMove = 0.9;
    const cultureTimeline = new TimelineMax();
    cultureTimeline.add('start', '0')
      .to(".culture-item-1", cultureFirstMove, {
        transform:'translate(-350%,23%)',
        ease:Linear.easeNone
      }, 'start')
      .to(".culture-item-2", cultureFirstMove, {
        transform:'translate(-298%,-161%)',
        ease:Linear.easeNone}, 'start')
      .to(".culture-item-3", cultureFirstMove, {
        transform:'translate(-187%,-31%)',
        ease:Linear.easeNone}, 'start')
      .to(".culture-item-4", cultureFirstMove, {
        transform:'translate(-102%,-380%)',
          ease:Linear.easeNone}, 'start')
      .to(".culture-item-5", cultureFirstMove, {
        transform:'translate(-48%,-134%)',
        ease:Linear.easeNone}, 'start')
      .to(".culture-item-6", cultureFirstMove, {
        transform:'translate(-142%,80%)',
        ease:Linear.easeNone}, 'start')
      .to(".culture-item-7", cultureFirstMove, {
        transform:'translate(80%,-250%)',
        ease:Linear.easeNone}, 'start')
      .to(".culture-item-8", cultureFirstMove, {
        transform: 'translate(-10%,20%)',
        ease:Linear.easeNone}, 'start')      
      // Movement after scrolling through div.
      .to(".culture-item-1", 0.1, {
        transform:'translate(-500%,23%)',
        ease:Linear.easeNone
      },'start+=0.9')
      .to(".culture-item-2", 0.1, {
        transform:'translate(-398%,-161%)',
        ease:Linear.easeNone},'start+=0.9')
      .to(".culture-item-3", 0.1, {
        transform:'translate(-287%,-31%)',
        ease:Linear.easeNone},'start+=0.9')
      .to(".culture-item-4", 0.1, {
        transform:'translate(-202%,-380%)',
          ease:Linear.easeNone},'start+=0.9')
      .to(".culture-item-5", 0.1, {
        transform:'translate(-148%,-134%)',
        ease:Linear.easeNone},'start+=0.9')
      .to(".culture-item-6", 0.1, {
        transform:'translate(-175%,80%)',
        ease:Linear.easeNone},'start+=0.9')
      .to(".culture-item-7", 0.1, {
        transform:'translate(0%,-250%)',
        ease:Linear.easeNone},'start+=0.9')
      .to(".culture-item-8", 0.1, {
        transform: 'translate(-40%,20%)',
        ease:Linear.easeNone},'start+=0.9')
    
    const cultureTextTimeline = new TimelineMax();
    cultureTextTimeline.add('start', '0')
      .to("#page-content__caption--country", 0.25, {
        transform: 'translate(88.88vh, 0) translateX(-50%)',
        opacity: 1,
        onUpdateParams: [
          '{self}',
          document.querySelector('#country-link')
        ],
        onUpdate: function(_timelineMax, elem) {
          elem.removeAttribute('disabled');
        }
      }, 'start+=0');

    scenes.culture = new ScrollMagic.Scene({
      triggerElement: "#culture-animation-pin",
      tweenChanges: true,
      reverse: true,
      duration: paneDuration
    })
      .setTween(cultureTimeline) 
      .addTo(controller);

    scenes.cultureText = new ScrollMagic.Scene({
      triggerElement: "#culture-animation-pin",
      tweenChanges: true,
      reverse: false,
      duration: paneDuration
    })
      .setTween(cultureTextTimeline) 
      .addTo(controller);

    if (debugAnimation) {
      scenes.culture.addIndicators({
        name: "culture",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
      scenes.cultureText.addIndicators({
        name: "culture",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
    }

    // Language animation.    
    const languageTimeline = new TimelineMax();
    languageTimeline.add('start', '0')
      .to(".language_head", 0.2, {
        transform: 'translate(-75%, -110%)',
        ease:Linear.easeNone
      },'start')
      .to(".language_top", 0.3, {
        transform: 'translate(-55%, -120%)',
        ease:Linear.easeNone
      },'start')
      .to(".language_right", 0.7, {
        transform: 'translate(-40%, -80%)',
        ease:Linear.easeNone
      },'start')
      .to(".language_upperLip", 0.9, {
        transform: 'translate(-80%, -29%)',
        ease:Linear.easeNone
      },'start')
      .to(".language_lowerlip", 0.9, {
        transform: 'translate(-67%, 56%)',
        ease:Linear.easeNone
      },'start')
      .to(".language_lowerlip", 0, {
        transform: 'translate(-67%, 73%)',
        ease:Linear.easeNone
      }, 'start+=2');
    
    const languageTextTimeline = new TimelineMax();
    languageTextTimeline.add('start', '0')
      .to("#page-content__caption--language", 0.1, {
        transform: 'translate(83.88vh, 0) translateX(-50%)',
        opacity: 1,
        onUpdateParams: [
          '{self}',
          document.querySelector('#language-link')
        ],
        onUpdate: function(_timelineMax, elem) {
          elem.removeAttribute('disabled');
        }
      }, 'start+=1');

    scenes.language = new ScrollMagic.Scene({
      triggerElement:"#language-animation-pin",
      tweenChanges: true,
      reverse: !disableBackScroll,
      duration: paneDuration
    })
      .setTween(languageTimeline)
      .addTo(controller);
    
    scenes.languageText = new ScrollMagic.Scene({
      triggerElement:"#language-animation-pin",
      tweenChanges: true,
      reverse: false,
      duration: paneDuration
    })
      .setTween(languageTextTimeline)
      .addTo(controller);

    if (debugAnimation) {
      scenes.language.addIndicators({
        name: "Language",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
      scenes.languageText.addIndicators({
        name: "Language",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
    }

    // Mob animation.
    const mobFirstMove = 2.0;
    const mobTimeline = new TimelineMax();
    mobTimeline.add('start', '0')
      .to(".mob_1", mobFirstMove, {
        transform:'translate(-100%, -55%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_12", mobFirstMove, {
        transform: 'translate(-5%, -55%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_6", mobFirstMove, {
        transform:'translate(-28%, -67%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_4", mobFirstMove, {
        transform: 'translate(153%, -67%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_bit1", mobFirstMove, {
        transform: 'translate(271%, 127%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_2", mobFirstMove, {
        transform: 'translate(-12%, 34%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_14", mobFirstMove, {
        transform: 'translate(-50%, 43%)',
        ease:Linear.easeOut
      },'start')
      
      .to(".mob_10", mobFirstMove, {
        transform: ' translate(-251%, -67%)',
        ease:Linear.easeOut
      },'start')

      .to(".mob_3", mobFirstMove, {
        transform: 'translate(-247%, 12%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_5", mobFirstMove, {
        transform: 'translate(-228%, -227%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_8", mobFirstMove, {
        transform: 'translate(-80%, -265%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_9", mobFirstMove, {
        transform: 'translate(-160%, 115%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_7", mobFirstMove, {
        transform: 'translate(-244%, 114%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_11", mobFirstMove, {
        transform: 'translate(-300%, 111%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_13", mobFirstMove, {
        transform :'translate(-506%, 77%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_15", mobFirstMove, {
        transform: 'translate(-148%, 283%)',
        ease:Linear.easeOut
      },'start')
      .to(".mob_bit2", mobFirstMove, {
        transform: 'translate(-89%, 352%)',
        ease:Linear.easeOut
      },'start')
      // Joined mob moves out of circle.
      .to(".mob", 1.5, {
        transform :'translate(-12%, 0%)',
        ease:Linear.easeIn
      },'start+=3');
    
    const mobTextTimeline = new TimelineMax();
    mobTextTimeline.add('start', '0')
      .to("#page-content__caption--mob", 0.2, {
        transform: 'translate(80.88vh, 0) translateX(-50%)',
        opacity: 1,
        onUpdateParams: [
          '{self}',
          document.querySelector('#mob-link')
        ],
        onUpdate: function(_timelineMax, elem) {
          elem.removeAttribute('disabled');
        }
      }, 'start+=0');

    scenes.mob = new ScrollMagic.Scene({
      triggerElement:"#mob-animation-pin",
      tweenChanges: true,
      reverse: true,
      duration: paneDuration
    })
      .setTween(mobTimeline)
      .addTo(controller);
    
    scenes.mobText = new ScrollMagic.Scene({
      triggerElement:"#mob-animation-pin",
      tweenChanges: true,
      reverse: false,
      duration: paneDuration
    })
      .setTween(mobTextTimeline)
      .addTo(controller);

    if (debugAnimation) {
      scenes.mob.addIndicators({
        name: "Mob",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
      scenes.mobText.addIndicators({
        name: "Mob",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
    }

    // Resistance Animation.
    const resistanceTimingFade = 0.9;
    const resistanceTimeline = new TimelineMax();
    resistanceTimeline.add('start', '0')
      .to(".resistance_finger", 0.3, {
        transform:'translate(-194%, -209%)',
        ease:Linear.easeNone
      }, 'start+=0')
      .to(".resistance_4shadow",0.3, {
        transform:'translate(-164%, -127%)', 
        ease: Linear.easeNone
      }, 'start+=0.3')
      .to(".resistance_5shadow", 0.3, {
        transform: 'translate(-196%, -92%)', 
        ease: Linear.easeNone
      }, 'start+=0.6')
      .to(".resistance_6shadow", 0.3, {
        transform:'translate(-143%, -151%)',
        ease: Linear.easeNone
      }, 'start+=0.9')
      .to(".resistance_7shadow", 0.3, {
        transform:'translate(-197%, -367%)',
        ease: Linear.easeNone
      }, 'start+=1.2')
      .to(".resistance_8shadow", 0.3, {
        transform:'translate(-185%, -162%)',
        ease: Linear.easeNone
      }, 'start+=1.5')
      .to(".resistance_1shadow", 0.3, {
        transform: 'translate(-142%, 57%)', 
        ease: Linear.easeNone
      }, 'start+=1.8')
      .to(".resistance_2shadow", 0.3, {
        transform: 'translate(-160%, -20%)', 
        ease: Linear.easeNone
      }, 'start+=2.1')
      .to(".resistance_3shadow", 0.3, {
        transform: 'translate(-175%, -20%)', 
        ease: Linear.easeNone
      }, 'start+=2.4')
      .to(".resitance_portalthumb", 0.3, {
        transform: 'translate(-137%, -506%)',
        ease: Linear.easeNone
      }, 'start+=2.7')
      .to(".resistance_portalmid", 0.3, {
        transform: 'translate(-656%, -7%)',
        ease:Linear.easeNone
      }, 'start+=3')
      .to(".resistance_portallow", 0.3, {
        transform: 'translate(-509%, 187%)',
        ease:Linear.easeNone
      }, 'start+=3.3')
      .to(".resistance_4shadow", resistanceTimingFade, { 
        opacity:0 
      }, 'start+=3.6')
      .to(".resistance_5shadow", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resistance_6shadow", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resistance_7shadow", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resistance_8shadow", resistanceTimingFade, { 
        opacity:0 
      }, 'start+=3.6')
      .to(".resistance_1shadow", resistanceTimingFade, { 
        opacity:0
        }, 'start+=3.6')
      .to(".resistance_2shadow", resistanceTimingFade, { 
        opacity:0 
        }, 'start+=3.6')
      .to(".resistance_3shadow", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resitance_portalthumb", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resistance_portalmid", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resistance_portallow", resistanceTimingFade, {
          opacity:0 
        }, 'start+=3.6')
      .to(".resistance_finger", resistanceTimingFade, { 
        opacity:0 
      }, 'start+=3.6')
      .to(".resistance_handonly", resistanceTimingFade, {
        opacity: 1,
        ease: Linear.easeOut
      }, 'start+=3.6')
      .to(".resistance_symbol_toplayer", 0.9, {
        opacity: 1,
        display:"block",
        ease: Linear.easeOut
      },'start+=5');
    
    const resistanceTextTimeline = new TimelineMax();
    resistanceTextTimeline.add('start', '0')
      .to('#page-content__caption--resistance', 0.2, {
        transform: 'translate(74.88vh, 0) translateX(-50%)',
        opacity: 1,
        onUpdateParams: [
          '{self}',
          document.querySelector('#resistance-link')
        ],
        onUpdate: function(_timelineMax, elem) {
          elem.removeAttribute('disabled');
        }
      }, 'start+=1');
      
    scenes.resistance = new ScrollMagic.Scene({
      triggerElement: "#resistance-animation-pin",
      tweenChanges: true,
      reverse: false,
      duration: paneDuration,
    })
      .setTween(resistanceTimeline)
      .addTo(controller);
    
    scenes.resistanceText = new ScrollMagic.Scene({
      triggerElement: "#resistance-animation-pin",
      tweenChanges: true,
      reverse: false,
      duration: paneDuration,
    })
      .setTween(resistanceTextTimeline)
      .addTo(controller);

    if (debugAnimation) {
      scenes.resistance.addIndicators({
        name: "Resistance",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
      scenes.resistanceText.addIndicators({
        name: "Resistance",
        colorTrigger: "white",
        colorStart: "white",
        colorEnd: "white"
      });
    }

    // Now, and only now, should we allow the line to be drawn.
    renderLineEnabled = true;
  };

  /**
   * Update each scene's duration with the new paneDuration.
   */
  var updateScene = function () {
    Object.keys(scenes).forEach(function (sceneKey) {
      scenes[sceneKey].duration(paneDuration);
    });
  };

  /**
   * Force scroll to the start, and initialise the scene.
   */
  var initPage = function (e) {
    // Instead of scrolling all the way to the left, let's centre on the first pane.
    console.log({
      paneCentreGutter: paneCentreGutter(0),
      scrollWidth: scrollWidth(),
      scrollHeight: scrollHeight()
    });
    const firstLoadVertDistance = paneCentreGutter(0) / scrollWidth() * scrollHeight();
    if (firstLoadVertDistance < 0) {
      leftHandGutter = firstLoadVertDistance;
    }
    scrollEventActive = true;
    $('html, body').animate({
      scrollTop: firstLoadVertDistance > 0 ? firstLoadVertDistance : 0
    }, 1000, function () {
      initScene();
    });
  };

  /**
   * Window size fetcher, plus a variable to keep track of it.
   */
  var getSize = function () {
    return {
      width: window.innerWidth,
      height: innerHeight()
    };
  };
  var windowSize = getSize();

  /**
   * Handle a window resize event.
   *
   * Check that the window size has changed, to ensure that we don't
   * capture any scroll events by accident.
   */
  var handleResizeEvent = function (e) {
    var currentSize = getSize();
    if (
      currentSize.width !== windowSize.width ||
      currentSize.height !== windowSize.height
    ) {
      windowSize = currentSize;

      // Set the new pane duration and update scenes accordinaly.
      paneDuration = innerHeight();

      updateScene();
    }
  };

  /*
    * All event listeners and callbacks for this page.
    */
  window.addEventListener('DOMContentLoaded', initPage.bind(this), false);
  window.addEventListener('scroll', handleScrollEvent.bind(this), false);
  window.addEventListener('resize', handleResizeEvent.bind(this), false);
})(jQuery);
