/**
 * @file
 * Maths utilities.
 */

const radians = Math.radians || (degrees => (degrees * (2 * Math.PI)) / 360);

export {
  radians,
}
