/**
 * @file
 * Functions that parse data attributes.
 */

const fromPercentage = (value) => parseFloat(value.replace('%', '')) / 100;

const parseDataAttribute = (attribute, format) => {
  if (!attribute || !attribute.length) {
    return [];
  }
  return attribute.replace(/[\s\r\n]/g, '')
    .split(";")
    .reduce((agg, item) => {
      if (!item.length) {
        return agg;
      }
      const [k, v] = item.split(":");
      let newV = v;

      switch (format) {
        case "width":
        case "opacity":
          newV = parseFloat(newV);
          break;
        
        case "z-index":
          newV = Math.round(parseFloat(newV));
          break;

        case "timing":
          newV = fromPercentage(newV);
          break;
      }

      if (k.indexOf("%") >= 0) {
        const newK = fromPercentage(k);
        agg.push({
          t: newK,
          value: newV
        });
      } else {
        agg.push({
          t: k,
          value: newV
        });
      }
      return agg;
    }, []);
};

export { parseDataAttribute };
